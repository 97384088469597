import React, { useState, useEffect } from 'react';
import { Clock, Edit2, Trash2, Plus, Save, Image as ImageIcon, ChefHat } from 'lucide-react';

function RecipePage() {
  const [recipes, setRecipes] = useState([]);
  const [newRecipe, setNewRecipe] = useState({
    title: '',
    ingredients: [{ name: '', amount: '', unit: '' }],
    instructions: '',
    createdAt: new Date()
  });
  const [editingId, setEditingId] = useState(null);

  useEffect(() => {
    const fetchRecipes = async () => {
      try {
        const response = await fetch('https://api.test.philipbendzko.com/api/recipes');
        if (!response.ok) {
          throw new Error('Failed to fetch recipes');
        }
        const data = await response.json();
        setRecipes(data);
      } catch (error) {
        console.error('Error fetching recipes:', error);
      }
    };

    fetchRecipes();
  }, []);

  const addIngredient = () => {
    setNewRecipe({
      ...newRecipe,
      ingredients: [...newRecipe.ingredients, { name: '', amount: '', unit: '' }]
    });
  };

  const removeIngredient = (index) => {
    setNewRecipe({
      ...newRecipe,
      ingredients: newRecipe.ingredients.filter((_, i) => i !== index)
    });
  };

  const updateIngredient = (index, field, value) => {
    const updatedIngredients = newRecipe.ingredients.map((ingredient, i) => {
      if (i === index) {
        return { ...ingredient, [field]: value };
      }
      return ingredient;
    });
    setNewRecipe({ ...newRecipe, ingredients: updatedIngredients });
  };

  const addRecipe = async () => {
    if (newRecipe.title && newRecipe.instructions) {
      try {
        const response = await fetch('https://api.test.philipbendzko.com/api/recipes', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(newRecipe),
        });
        
        if (!response.ok) {
          throw new Error('Failed to add recipe');
        }
        
        const savedRecipe = await response.json();
        setRecipes([...recipes, savedRecipe]);
        setNewRecipe({
          title: '',
          ingredients: [{ name: '', amount: '', unit: '' }],
          instructions: '',
          createdAt: new Date()
        });
      } catch (error) {
        console.error('Error adding recipe:', error);
      }
    }
  };

  const deleteRecipe = async (id) => {
    try {
      const response = await fetch(`https://api.test.philipbendzko.com/api/recipes/${id}`, {
        method: 'DELETE',
      });
      
      if (!response.ok) {
        throw new Error('Failed to delete recipe');
      }
      
      setRecipes(recipes.filter(recipe => recipe._id !== id));
    } catch (error) {
      console.error('Error deleting recipe:', error);
    }
  };

  const startEditing = (id) => {
    const recipeToEdit = recipes.find(recipe => recipe._id === id);
    setNewRecipe(recipeToEdit);
    setEditingId(id);
  };

  const saveEdit = async (id) => {
    try {
      const response = await fetch(`https://api.test.philipbendzko.com/api/recipes/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newRecipe),
      });
      
      if (!response.ok) {
        throw new Error('Failed to update recipe');
      }
      
      const updatedRecipe = await response.json();
      setRecipes(recipes.map(recipe => recipe._id === id ? updatedRecipe : recipe));
      setEditingId(null);
      setNewRecipe({
        title: '',
        ingredients: [{ name: '', amount: '', unit: '' }],
        instructions: '',
        createdAt: new Date()
      });
    } catch (error) {
      console.error('Error updating recipe:', error);
    }
  };

  return (
    <div className="w-full max-w-6xl mx-auto">
      {/* Top Bar */}
      <div className="bg-white shadow-md rounded-lg p-4 mb-8 flex items-center">
        <ChefHat className="w-8 h-8 text-blue-600 mr-4" />
        <h1 className="text-2xl font-bold text-gray-900">Recipe Collection</h1>
      </div>

      <div className="bg-white rounded-lg shadow-md p-6 mb-8">
        <div className="space-y-4">
          <input
            type="text"
            placeholder="Recipe Title"
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={newRecipe.title}
            onChange={(e) => setNewRecipe({ ...newRecipe, title: e.target.value })}
          />

          {/* Ingredients Section */}
          <div className="space-y-2">
            <div className="flex justify-between items-center">
              <h3 className="text-lg font-medium">Ingredients</h3>
              <button
                onClick={addIngredient}
                className="text-blue-600 hover:text-blue-700"
              >
                <Plus className="w-4 h-4" />
              </button>
            </div>
            
            {newRecipe.ingredients.map((ingredient, index) => (
              <div key={index} className="flex gap-2">
                <input
                  type="text"
                  placeholder="Ingredient name"
                  className="flex-1 px-4 py-2 border border-gray-300 rounded-md"
                  value={ingredient.name}
                  onChange={(e) => updateIngredient(index, 'name', e.target.value)}
                />
                <input
                  type="number"
                  placeholder="Amount"
                  className="w-24 px-4 py-2 border border-gray-300 rounded-md"
                  value={ingredient.amount}
                  onChange={(e) => updateIngredient(index, 'amount', e.target.value)}
                />
                <input
                  type="text"
                  placeholder="Unit"
                  className="w-24 px-4 py-2 border border-gray-300 rounded-md"
                  value={ingredient.unit}
                  onChange={(e) => updateIngredient(index, 'unit', e.target.value)}
                />
                <button
                  onClick={() => removeIngredient(index)}
                  className="text-red-600 hover:text-red-700"
                >
                  <Trash2 className="w-4 h-4" />
                </button>
              </div>
            ))}
          </div>

          <textarea
            placeholder="Instructions"
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 h-32 resize-none"
            value={newRecipe.instructions}
            onChange={(e) => setNewRecipe({ ...newRecipe, instructions: e.target.value })}
          />

          <button
            onClick={editingId ? () => saveEdit(editingId) : addRecipe}
            className="w-full bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded-md transition-colors flex items-center justify-center gap-2"
          >
            {editingId ? <Save className="w-4 h-4" /> : <Plus className="w-4 h-4" />}
            {editingId ? 'Update Recipe' : 'Add Recipe'}
          </button>
        </div>
      </div>

      {/* Recipe List Table */}
      <div className="bg-white rounded-lg shadow-md overflow-hidden">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Title
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Ingredients
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Instructions
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {recipes.map(recipe => (
              <tr key={recipe._id} className="hover:bg-gray-50">
                <td className="px-6 py-4">
                  <div className="text-sm font-medium text-gray-900">{recipe.title}</div>
                </td>
                <td className="px-6 py-4">
                  <div className="text-sm text-gray-900">
                    {recipe.ingredients.map((ing, index) => (
                      <div key={index}>
                        {ing.amount} {ing.unit} {ing.name}
                      </div>
                    ))}
                  </div>
                </td>
                <td className="px-6 py-4">
                  <div className="text-sm text-gray-900 truncate max-w-xs">
                    {recipe.instructions}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                  <div className="flex gap-2">
                    <button
                      onClick={() => startEditing(recipe._id)}
                      className="inline-flex items-center px-3 py-1 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
                    >
                      <Edit2 className="w-4 h-4 mr-1" />
                      Edit
                    </button>
                    <button
                      onClick={() => deleteRecipe(recipe._id)}
                      className="inline-flex items-center px-3 py-1 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-red-600 hover:bg-red-700"
                    >
                      <Trash2 className="w-4 h-4 mr-1" />
                      Delete
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default RecipePage; 