import RecipePage from './components/RecipePage';

function App() {
  return (
    <div className="min-h-screen flex flex-col bg-gray-50">
      <main className="flex-1 py-8 px-4">
        <RecipePage />
      </main>
    </div>
  );
}

export default App;
